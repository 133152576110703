import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Preloader } from "./components/Preloader";
import { clearPreloader, setScrollUp } from "./showcase";
import { MainContent } from "./components/MainContent";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const MENU_ITEMS_URL="/json/menu_items.json";
const PAGES_URL="/json/pages";
const FOOTER_URL="/json/footer_items.json";

export function Page({ style }) {
  const [menuItems, setMenuItems] = useState([]);
  const [pageData, setPageData] = useState({ style: style });
  const [footerData, setFooterData] = useState([]);
  const params = useParams();
  const location = useLocation(); // Get the current location
  useEffect(() => {
    setScrollUp();

    // async function fetchMenuItems() {
    //   try {
    //     const directoryPath = path.join(__dirname, '../json');
    //     const filePath = path.join(directoryPath, `menuData.json`);
    //     const json = fs.readFileSync(filePath);
    //     if (json) {
    //       const data = JSON.parse(json);
    //       return data;
    //     } else {
    //       console.log(res);
    //       throw new Error("No data error.");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // }

    // async function fetchPageData(slug) {
    //   try {
    //     const directoryPath = path.join(__dirname, '../json');
    //     const filePath = path.join(directoryPath, `page-${slug}.json`);
    //     const json = fs.readFileSync(filePath);
    //     if (json) {
    //       const data = await res.json();
    //       return data.data;
    //     } else {
    //       console.log(res);
    //       throw new Error("No data error.");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // }

    // async function fetchFooterData() {
    //   try {
    //     const directoryPath = path.join(__dirname, '../json');
    //     const filePath = path.join(directoryPath, `footerData.json`);
    //     const json = fs.readFileSync(filePath);
    //     if (res.ok) {
    //       const data = await res.json();
    //       return data.data;
    //     } else {
    //       console.log(res);
    //       throw new Error("Network error.");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // }

    async function fetchMenuItems() {
      try {
        const res = await fetch(MENU_ITEMS_URL);
        if (res.ok) {
          const data = await res.json();
          return data;
        } else {
          console.log(res);
          throw new Error("Network error.");
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    async function fetchPageData(slug) {
      try {
        const res = await fetch(`${PAGES_URL}/${slug}.json`);
        if (res.ok) {
          const data = await res.json();
          return data;
        } else {
          console.log(res);
          throw new Error("Network error.");
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    async function fetchFooterData() {
      try {
        const res = await fetch(FOOTER_URL);
        if (res.ok) {
          const data = await res.json();
          return data;
        } else {
          console.log(res);
          throw new Error("Network error.");
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    }

    (async () => {
      const menuData = await fetchMenuItems();
      const slug = params.slug || menuData[0].slug;
      const pageData = await fetchPageData(slug);
      const footerData = await fetchFooterData();

      setMenuItems(menuData);
      setPageData(pageData);
      setFooterData(footerData);
      clearPreloader();
    })();
  }, [location]);

  return (
    <>
      {
        pageData.style == "preloader" ?
          <Preloader /> :
          <Header menuItems={menuItems} style={pageData.style} />
      }
      <div className="main-content" id="main-content">
        <MainContent pages={menuItems} page={pageData} />
      </div>
      <Footer items={footerData} />
    </>
  );
}
