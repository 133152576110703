import React from "react";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export function Thumbnails({ colour, title, thumbnails, width }) {
  const cssWidth = width ? `is-${width}` : 'is-12';

  // function hashLinkClickHandler(e, to) {
  //   const url = new URL(to, window.location);
  //   if (window.location.pathname === url.pathname) {
  //     e.preventDefault();
  //     const anchor = document.querySelector(url.hash);
  //     if (anchor) {
  //       window.location.hash = url.hash.substring(1);
  //       setTimeout(() => {
  //         anchor.scrollIntoView({ behavior: "smooth" });
  //       }, 100);
  //     }
  //   }
  // }

  // function HashLink({ to, children }) {
  //   // return to.includes("#") ? (
  //     //return <Link to={to} onClick={(e) => hashLinkClickHandler(e, to)}>{children}</Link>;
  //     return <Link to={to}>{children}</Link>;
  //   // ) : (
  //     // <Link to={to}>{children}</Link>
  //   // );
  // }

  function Thumbnail({ title, image, link }) {
    return (
      <div className="column is-3">
          <Link to={link}>
            <figure
              className="image is-2by1 work-item"
              style={{
                backgroundImage: `url("${image}")`,
              }}
            />
            <figcaption>{title}</figcaption>
          </Link>
      </div>
    );
  }

  const classes = `section-${colour} thumbnails`;

  return (
    <div className={classes}>
      <div className="container">
        <div
          className="columns is-multiline"
          data-aos="fade-in"
          data-aos-easing="linear"
        >
          <div className={`column ${cssWidth}`}>
            <h1 className="title has-text-centered section-title">{title}</h1>
          </div>
          {thumbnails.map((thumbnail) => (
            <Thumbnail key={thumbnail.id} {...thumbnail} />
          ))}
        </div>
      </div>
    </div>
  );
}
