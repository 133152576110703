import "./App.scss";
import "./_sass/showcase.scss";
import { RouterProvider } from "react-router-dom";
import React from "react";
import router from "./routes.jsx";
import "bulma";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init({
    easing: "ease-out",
    duration: 800,
  });
  
  return (
    <>
      <RouterProvider router={router} />
    </> 
  );
}

export default App;
