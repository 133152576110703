// Set preloader
window.addEventListener("load", function () {
  var body = document.querySelector("body");
  body.classList.add("preloader-site");
});

// Clear preloader
export function clearPreloader() {
  var preloaderWrapper = document.querySelector(".preloader-wrapper");
  var body = document.querySelector("body");

  if (preloaderWrapper) {
    preloaderWrapper.style.display = "none";
  }
  body.classList.remove("preloader-site");
}

// When the user scrolls down 20px from the top of the document, show the scroll up button
export function setScrollUp() {
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById("toTop").style.display = "block";
    } else {
      document.getElementById("toTop").style.display = "none";
    }
  }
}