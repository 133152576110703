import React, { useState, useEffect } from 'react';
import MarkdownElement from 'react-markdown'
import Anchor from '../Anchor';

// function AccordionSectionListItem({ item }) {
//   return (
//     <li>
//     <MarkdownElement>{item}</MarkdownElement>
//     </li>
//   );
// }

// function AccordionSectionList({ list }) {
//   return (
//     <>
//       <ul>
//           {list.map((item, index) => (
//             <AccordionSectionListItem key={`item-${index}`} item={item} />
//           ))}
//       </ul>
//     </>
//   );
// }

function Section({ index, isActive, setActive, section: { title, body, slug } }) {

  const [uuid, setUuid] = useState(crypto.randomUUID());
  const [currentlyActive, setCurrentlyActive] = useState(false);

  const anchor = `accordion-section-${uuid}-${index}`;

  // Auto-close other sections when opening a new one
  // if (isActive != currentlyActive) {
  //   setCurrentlyActive(isActive);
  // }

  function handleAnchorClick(event) {
    // setActive(isActive ? null : index);
    // if (isActive) return;
    setCurrentlyActive(!currentlyActive);
  }

  return (
    <div id={anchor} className={`accordion-section${currentlyActive ? ' open' : ''}`} onClick={() => handleAnchorClick() }>
      <Anchor id={slug} />
      <div className="accordion-handle">
        <h4 className="subtitle">
          {title}
        </h4>
        <span className="accordion-toggle icon" />
      </div>
      <section className="content">
        <div className="inner column is-12 is-centered">
          {body && <div><MarkdownElement>{body}</MarkdownElement></div>}
        </div>
      </section>
    </div>
  );
}

export function Accordion({ colour, title, body, width, sections }) {
  const classes = `section-${colour} accordion`;
  const cssWidth = width ? `is-${width}` : 'is-10';

  const [activeSection, setActiveSection] = useState(null);

  const activateSection = (index) => {
    setActiveSection(index);
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const parent = element.parentElement;
        if (parent) {
          parent.click();
        }
      }
    }
  }, []);

  return (
    <div className={classes}>
      <div className="container">
        <div className="columns is-multiline is-centered has-text-centered">
          <div
            className={`column ${cssWidth}`}
            data-aos="fade-in"
            data-aos-easing="linear"
          >
            <h1 className="title section-title">{title}</h1>
            <h2 className="subtitle">{body}</h2>
            <br />
          </div>
        </div>
        <div className="columns is-multiline is-centered">
          <div
            className={`column ${cssWidth} has-text-left`}
            data-aos="fade-in"
            data-aos-easing="linear"
          >
            {sections.map((section, index) => (
              <Section index={index} isActive={index == activeSection} setActive={activateSection} key={`section-${index}`} section={section} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
