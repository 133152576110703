import React from "react";
import { chunk } from "../../lib/arrayUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas)

export function IconList({ colour, title, body, items, width }) {
  const cssWidth = width ? `is-${width}` : 'is-12';
  const chunks = chunk(items, 2);

  function IconRow({ items }) {
    return (
      <>
        <hr />
        <div className="columns is-12 is-centered">
          {items.map((item) => (
            <IconItem key={`bullet-${item.id}`} {...item} />
          ))}
        </div>
      </>
    );
  }

  function IconItem({ icon, title, body }) {
    return (
      <div
        className="column is-6 has-text-centered"
        data-aos="fade-in"
        data-aos-easing="linear"
      >
        <FontAwesomeIcon icon={icon} size="3x" />
        <hr />
        <h2 className="subtitle">{title}</h2>
        <p>{body}</p>
      </div>
    );
  }

  const classes = `section-${colour} icon-list`;

  return (
    <div className={classes}>
      <div className="container">
        <div className="columns is-multiline">
          <div
            className={`column ${cssWidth}`}
            data-aos="fade-in"
            data-aos-easing="linear"
          >
            <h1 className="title has-text-centered section-title">{title}</h1>
            <h2 className="subtitle has-text-centered">{body}</h2>
            <br />
          </div>
          {chunks.map((chunk, index) => (
            <IconRow key={`bullet-row-${index}`} items={chunk} />
          ))}
        </div>
      </div>
    </div>
  );
}
