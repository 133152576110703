import React from "react";
import { split } from "../../lib/arrayUtils";

export function ProgressBars({ colour, title, progressBars, width }) {
  const cssWidth = width ? `is-${width}` : 'is-12';
  const chunks = split(progressBars, 2);

  function ProgressBar({ title, value, max }) {
    return (
      <>
        <h1>{title}</h1>
        <progress className="progress" value={value} max={max}>
          {value}%
        </progress>
      </>
    );
  }

  function ProgressBarColumns({ progressBars }) {
    return (
      <div className="column is-6" data-aos="fade-in" data-aos-easing="linear">
        {progressBars.map((progressBar) => (
          <ProgressBar key={progressBar.id} {...progressBar} />
        ))}
      </div>
    );
  }

  const classes = `section-${colour} progress-bars`;

  return (
    <div className={classes}>
      <div className="container">
        <div className="columns is-multiline">
          <div className={`column ${cssWidth}`}>
            <h1 className="title has-text-centered section-title">{title}</h1>
          </div>
          {chunks.map((chunk, i) => (
            <ProgressBarColumns key={i} progressBars={chunk} />
          ))}
        </div>
      </div>
    </div>
  );
}
