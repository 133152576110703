export function chunk(arr, chunkSize) {
  const chunks = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}

export function split(arr, numChunks) {
  const chunkSize = Math.ceil(arr.length / numChunks);
  return chunk(arr, chunkSize);
}
