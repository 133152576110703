import React from "react";

export function Contact({colour, title, name, email, message, submit, placeholderName, placeholderEmail, placeholderMessage, width }) {
  const classes = `section-${colour} contact`;
  const cssWidth = width ? `is-${width}` : 'is-12';

  return (
    <div className={classes} id="contact">
      <div className="container">
        <div
          className="columns is-multiline"
          data-aos="fade-in-up"
          data-aos-easing="linear"
        >
          <div className={`column ${cssWidth}`}>
            <h1 className="title has-text-centered section-title">
              {title}
            </h1>
          </div>
          <div className="column is-8 is-offset-2">
            <form action="https://formspree.io/email@example.com" method="POST">
              <div className="field">
                <label className="label">Name</label>
                <div className="control has-icons-left">
                  <input
                    className="input"
                    type="text"
                    placeholder="Ex. Jane Smith"
                    name={placeholderName}
                    val={name}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left">
                  <input
                    className="input"
                    type="email"
                    placeholder={placeholderEmail}
                    name="Email"
                    val={email}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope" />
                  </span>
                </div>
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Textarea"
                    name="Message"
                    defaultValue={placeholderMessage}
                    val={message}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control ">
                  <button className="button submit-button">
                    {submit}&nbsp;&nbsp;
                    <i className="fas fa-paper-plane" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
