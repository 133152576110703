import { InfoPanel } from './components/fragments/InfoPanel.jsx';
import { useRouteError } from "react-router-dom";
import { Preloader } from "./components/Preloader.jsx";
import { Header } from "./components/Header.jsx";
import React, { useState, useEffect } from "react";
import { clearPreloader, setScrollUp } from "./showcase.js";

const menuItemsUrl = "http://localhost:3000/api/v1/menu_items"; // TODO: move to .env

const infoPanelData = {
  colour: "light",
  title: "Error",
  subtitle: 'An error has occurred.',
  body: 'Please try navigating to your desired page from the homepage',
  ctas: null
};

const defaultMenu = [
  {
      "id": 1,
      "active": true,
      "external_url": "/",
      "link_text": "Home",
      "is_external": true,
      "page_id": 1,
      "position": 1
  }
];

function ErrorPage() {
  const [menuItems, setMenuItems] = useState([]);
  const error = useRouteError();
  console.error(error);

  if (error?.status) {
    infoPanelData.title = `${error.status}: ${error.statusText}`;
    infoPanelData.subtitle = error.data;
  }

  useEffect(() => {
    clearPreloader();
    setScrollUp();

    fetch(menuItemsUrl)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        console.log("Error retrieving menu, returned: ", res);
        return defaultMenu;
      })
      .then((res) => setMenuItems(res.data))
      .catch((error) => {
        console.error(error);
        setMenuItems(defaultMenu);
      });
  }, []);

  return (
    <>
      <Preloader />
      <Header menuItems={menuItems}/>
      <div className="main-content">
        <InfoPanel {...infoPanelData} />
      </div>
    </>
  );
}

export default ErrorPage;