import { Link } from "react-router-dom";
import React from "react";
import { useLocation } from "react-router-dom";


export function HeroMenu({ menuItems }) {
  const location = useLocation();

  function HeroMenuItem({ external_url, link_text, is_external, slug }) {
    const path = !is_external && `/pages/${slug}`;
    const isCurrentPath = !is_external && (location.pathname === path || slug == "home" && location.pathname === "/"); // TODO: Find a better way to do this!

    return (
      <>
      <li className={isCurrentPath ? "is-active" : ""}>
      { is_external  && <a href={external_url}>{link_text}</a> }
      { !is_external && <Link className="navbar-item" to={`/pages/${slug}`}>{link_text}</Link> }
      </li>
      </>
    );
  }

  return (
    <div className="hero-foot ">
      <div className="hero-foot--wrapper">
        <div className="columns">
          <div className="column is-12 hero-menu-desktop has-text-centered is-fixed-top">
            <ul>
              {menuItems.map((item) => <HeroMenuItem key={item.slug} {...item} />)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
