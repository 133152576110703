import React, {useEffect} from "react";
import { MobileNav } from "./navigation/MobileNav";
import { HeroMenu } from "./navigation/HeroMenu";

const heroContent = {
  title: "Raising Agents",
  logoAlt: "Raising Agents logo: abstract turquoise bubbles with some spots of hot pink",
  logo: "/logo/wide_light_modern.svg",
  video: "https://d1r543w7y3561h.cloudfront.net/Bubbles-processed.mp4"
};

function HeroContent({ title, logo, video }) {

  useEffect(() => {
    var video = document.querySelector('#background-video'); // get the video element
    var tracks = video.textTracks; // one for each track element
    var track = tracks[0]; // corresponds to the first track element
    track.mode = 'hidden';
  }, []);

  return (
    <div className="hero-body">
      <video id="background-video" autoPlay loop muted>
        <source src={video} type="video/mp4"/>
        <track default kind="captions" srcLang="en" src="/hero-video.vtt" />
      </video>
      <div className="container has-text-centered">
        <h1 className="subtitle"><img src={logo} alt={title}/></h1>
      </div>
    </div>
  );
}

export function Header({menuItems, style}) {
  return (
    <>
    <a className="skip-to-content-link" href="#main-content">Skip to content</a>
    <div className="header-wrapper" id="home">
      <section className={"hero is-large" + (style == "standard" ? " standard-page" : "")}>
        <MobileNav menuItems={menuItems} />
        <HeroContent {...heroContent} />
        <HeroMenu menuItems={menuItems} />
      </section>
    </div>
    </>
  );
}