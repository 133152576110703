import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export function MobileNav({ menuItems }) {
  const [active, setActive] = useState(false);

  function handleClickBurger() {
    setActive((active) => !active);
  }

  function BurgerMenu() {
    const classnames = `navbar-burger burger${active ? ' is-active' : ''}`;
    return (
      <div className="navbar-brand">
        <div onClick={handleClickBurger} className={classnames} data-target="mobile-nav">
          <span />
          <span />
          <span />
        </div>
      </div>
    );
  }

  function NavbarItem({ external_url, link_text, is_external, slug }) {
    return (
      <div className="navbar-item">
        { is_external && <a className="navbar-item" href={external_url}>{link_text}</a> }
        { !is_external && <Link className="navbar-item" to={`/pages/${slug}`}>{link_text}</Link> }
      </div>);
  }

  const classnames = `navbar-menu${active ? ' is-active' : ''}`;
  return (
    <nav className="navbar is-fixed-top is-transparent is-hidden-desktop">
      <BurgerMenu />
      <div id="mobile-nav" className={classnames}>
        <div className="navbar-end">
          {menuItems.map((item) => <NavbarItem key={item.slug} {...item} />)}
        </div>
      </div>
    </nav>
  );
}
