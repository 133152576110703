import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas)

export function Download({ colour, title, subtitle, buttonText, buttonLink, width }) {
  const classes = `section-${colour} download`;
  const cssWidth = width ? `is-${width}` : 'is-12';

  return (
    <div className={classes}>
      <div className="container">
        <div
          className="columns is-multiline"
          data-aos="fade-in"
          data-aos-easing="linear"
        >
          <div className={`column ${cssWidth}`}>
            <h1 className="title has-text-centered section-title">{title}</h1>
          </div>
          <div className="column is-10 has-text-centered is-offset-1">
            {subtitle && <h2 className="subtitle">{subtitle}</h2>}
            <form action={buttonLink}>
              <button className="button">
                {buttonText}&emsp;<FontAwesomeIcon icon="fa-envelope" size="lg" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
