import React from "react";
import MarkdownElement from 'react-markdown'
import Anchor from '../Anchor';

export function InfoPanel({ colour, slug, title, subtitle, body, ctas, imageUrl, imageAlt, width, imageIsLeft }) {
  width = width || 10;
  const halfWidth = Math.floor(parseInt(width, 10) / 2);
  const fullWidth = halfWidth * 2;
  if (halfWidth == 0) {
    fullWidth = 2;
    halfWidth = 1;
  }

  const cssWidth = `is-${fullWidth}` || 'is-12';
  const cssHalfWidth = `is-${halfWidth}` || 'is-6';

  function InfoPanelCTA({ id, title, linkText, link }) {
    return (
      <div key={id} className="column">
        <p className="heading">
          <strong>{title}</strong>
        </p>
        {link && (
          <a className="subheading" href={link}>
            {linkText}
          </a>
        )}
        {!link && <p className="subheading">{linkText}</p>}
      </div>
    );
  }

  const classes = `section-${colour} info-panel`;

  const subtitleElements = <>
    <p className="is-larger has-text-centered">
      <strong>{subtitle}</strong>
    </p>
    <br />
  </>

  const TextPanel = <div
    className={`column ${cssHalfWidth} has-vertically-aligned-content`}
    data-aos="fade"
  >
    {subtitle && subtitleElements}
    <MarkdownElement>{body}</MarkdownElement>
    <br />
    <div className="is-divider" />
    {ctas && ctas.length > 0 &&
      <div className="columns about-links">
        {ctas.map((cta) => (
          <InfoPanelCTA key={cta.title} {...cta} />
        ))}
      </div>
    }
  </div>

  const ImagePanel = <div className={`column ${cssHalfWidth} right-image `} data-aos="fade">
    <img
      className="is-rounded"
      src={imageUrl}
      alt={imageAlt}
    />
  </div>

  return (
    <div className={classes}>
      <Anchor id={slug} />
      <div className="container">
        <div className="columns is-centered">
          <div className={`column ${cssWidth}`}>
            <h1 className="title has-text-centered section-title is-centered">{title}</h1>
          </div>
        </div>
        <div className={`columns is-multiline ${cssWidth} is-centered`}>

          {imageIsLeft ? ImagePanel : TextPanel}
          {imageIsLeft ? TextPanel : ImagePanel}
        </div>
      </div>
    </div>
  );
}
