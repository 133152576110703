import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Page } from "./Page";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Page style="home" /> },
      { path: "/pages/:slug", element: <Page style="standard" /> },
    ],
  }
]);

export default router;
