import React from "react";
import { InfoPanel } from "./fragments/InfoPanel";
import { Download } from "./fragments/Download";
import { IconList } from "./fragments/IconList";
import { ProgressBars } from "./fragments/ProgressBars";
import { Contact } from "./fragments/Contact";
import { Thumbnails } from "./fragments/Thumbnails";
import { Accordion } from "./fragments/Accordion";
import { Markdown } from "./fragments/Markdown";

export function MainContent({ page }) {
  page.fragments ||= [];

  const components = {
    "InfoPanel": InfoPanel,
    "Download": Download,
    "IconList": IconList,
    "ProgressBars": ProgressBars,
    "Contact": Contact,
    "Thumbnails": Thumbnails,
    "Accordion": Accordion,
    "Markdown": Markdown
  };
  
  const NullComponent = () => {
    return;
  }

  const content = page.fragments.map((fragment, index) => {
    const component = components[fragment.fragmentType] || NullComponent;
    return React.createElement(component, { ...fragment, key: index });
  });

  return (
    <>
      {content}
    </>
  );
}
